import { Component, OnInit } from '@angular/core';
import { ChatserviceService } from 'src/app/Composants/apps/chat/chatservice/chatservice.service';
import { BienService } from 'src/app/services/bien.service';
import { LoginService } from 'src/app/services/login.service';
import { ProductService } from 'src/app/shared/services/product.service';
import * as moment from 'moment';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification: boolean = false;
  candidatures: any[]; // Assurez-vous de typer correctement vos candidatures
  total: any[]; // Même chose pour total
  givenDate: Date;

  temps: Date;
  Userconnecter: any;
  unreadCountListe: any;
  notif: any;
  notifications: any;
  

  constructor(private bienService: BienService,

    private connexion: LoginService,
    private chatService: ChatserviceService,
  ) {
    this.chatService.initConnectionSocket();

    this.givenDate = new Date("2023-10-14T00:02:37.000Z");
  }

  ngOnInit() { 

    this.connexion.AfficherUserConnecter().subscribe((data) => {
      this.Userconnecter = data[0];

      this.Userconnecter = data && data.length > 0 ? data[0] : null;
      this.chatService.joinRoom3(this.Userconnecter.uuid); 

      this.chatService.getUnreadCountListe().subscribe((unreadCountliste: any) => {
        this.unreadCountListe = unreadCountliste;
 
        
 
 
     
      if (unreadCountliste === 0) {
         this.connexion.getUnreadNotificationsCount().subscribe(
           (data) => {
             this.notif = data;
           });
           this.connexion.AfficherListeNotification().subscribe(data => {
             // Filtrer les notifications pour prendre uniquement celles qui ne sont pas lues (lu: false)
             const unreadNotifications = data.filter((notification: { lu: boolean }) => !notification.lu);
     
             // Prendre les deux premières notifications non lues
             this.notifications = unreadNotifications?.reverse();
             
             
           });
        }else{
         const unreadNotifications = unreadCountliste.filter((notification: { lu: boolean }) => !notification.lu);
 
         // Prendre les deux premières notifications non lues
    
  
         this.notifications = unreadNotifications?.reverse();
         
 
          this.notif = this.notifications?.length;
        }
 
       
 
      });


    });

    this.bienService.AfficherCandidature().subscribe((data) => {
      this.candidatures = data;
 
      // Filtrer les candidatures
      this.total = this.candidatures.filter(
        (candidature) => !candidature.isCancel && !candidature.isAccepted
      );


      this.total.forEach((candidature) => {
      
        candidature.temps = new Date(candidature?.updateAt); 
       
      });
    });
  }
  getDurationLabel(createdAt: Date | string): string {
    const createdAtDate = typeof createdAt === 'string' ? new Date(createdAt) : createdAt;
    
    if (!(createdAtDate instanceof Date) || isNaN(createdAtDate.getTime())) {
      return 'Date invalide';
    }
  
    const now = new Date();
    const timeDifference = now.getTime() - createdAtDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    const formatTime = (date: Date): string => {
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${hours}h${minutes}`;
    };
  
    const formatDate = (date: Date): string => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };
  
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  
    const getDayOfWeek = (date: Date): string => {
      return daysOfWeek[date.getDay()];
    };
  
    if (secondsDifference < 60) {
      return secondsDifference < 10 ? "à l'instant" : "il y a quelques secondes";
    } else if (minutesDifference < 60) {
      return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''}`;
    } else if (hoursDifference < 24) {
      return `il y a ${hoursDifference} heure${hoursDifference > 1 ? 's' : ''} et ${minutesDifference % 60} minute${minutesDifference % 60 > 1 ? 's' : ''}`;
    } else if (daysDifference === 1) {
      return `Hier à ${formatTime(createdAtDate)}`;
    } else if (daysDifference <= 7) {
      return `${getDayOfWeek(createdAtDate)} dernier à ${formatTime(createdAtDate)}`;
    } else {
      return `Le ${formatDate(createdAtDate)}`;
    }
  }
  
  

    getTimeFromNow(date: string): string {
    const dateMoment = moment(date);
    const now = moment();

    if (now.diff(dateMoment, 'days') === 0) {
      return dateMoment.fromNow(); // Aujourd'hui
    } else if (now.diff(dateMoment, 'days') === 1) {
      return dateMoment.calendar(); // Hier
    } else if (now.diff(dateMoment, 'days') > 1 && now.diff(dateMoment, 'days') < 7) {
      return dateMoment.calendar(); // Les autres jours de la semaine
    } else {
      return dateMoment.format('LLLL'); // À partir d'une semaine
    }
  }

  removeUnderscoresAndDashes(text: string | undefined): string {
    return text ? text.replace(/[-_]/g, ' ') : '';
  }
  
  
  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
  }

}
