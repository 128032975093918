<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="login-card">
          <div>
            <div>
              <a class="logo" routerLink="/">
                <img
                  class="img-fluid for-light"
                  src="assets/images/logo/sossara.png"
                  alt="looginpage"
                  style="   max-width: 50%;"
                />
                <img
                  class="img-fluid for-dark"
                  src="assets/images/logo/sossara_dark.png"
                  alt="looginpage"
                  style="   max-width: 50%;"
                />
              </a>
            </div>
            <div class="login-main">
              
              <!-- <h4>Reset Your Password</h4> -->
              <!-- <div class="form-group">
                  <label class="col-form-label">Enter Your Mobile Number</label>
                  <div class="row">
                    <div class="col-md-3">
                      <input class="form-control mb-1" type="text" value="+ 91">
                    </div>
                    <div class="col-md-9">
                      <input class="form-control mb-1" type="tel" value="000-000-0000">
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary d-block w-100 m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div> -->
              <!-- <div class="text-center mt-4 mb-4"><span class="reset-password-link">If don't receive OTP?  <a class="btn-link text-danger" routerLink='/'>Resend</a></span></div>
                <div class="form-group">
                  <label class="col-form-label pt-0">Enter OTP</label>
                  <div class="row">
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                    </div>
                    <div class="col">
                      <input class="form-control text-center opt-text" type="text" value="00" maxlength="2">
                    </div>
                  </div>
                </div> -->
              <h6 class="mt-4">Créer votre nouveau mot de passe</h6>
              <form class="theme-form" [formGroup]="newPassword">
                <div class="form-group">
                  <label class="col-form-label">Nouveau mot de passe</label>
                  <input
                    class="form-control"
                    [type]="show ? 'text' : 'password'"
                    formControlName="password"
                    required=""
                    placeholder="*********"
                  />
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                    <i class="fa fa-eye"></i>
                  </div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show">
                    <i class="fa fa-eye-slash"></i>
                  </div>
                </div>
                <div
                  *ngIf="
                    newPassword.controls.password.touched &&
                    newPassword.controls.password.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  Mot de passe oblogatoire
                </div>
                <div class="form-group">
                  <label class="col-form-label"
                    >Confirmez votre mot de passe</label
                  >
                  <input
                    class="form-control"
                    [type]="showcn ? 'text' : 'password'"
                    formControlName="cnPpassword"
                    required=""
                    placeholder="*********"
                  />
                  <div
                    class="show-hide"
                    (click)="showCnPassword()"
                    *ngIf="!showcn"
                  >
                    <i class="fa fa-eye"></i>
                  </div>
                  <div
                    class="show-hide"
                    (click)="showCnPassword()"
                    *ngIf="showcn"
                  >
                    <i class="fa fa-eye-slash"></i>
                  </div>
                </div>

                <div
                  *ngIf="
                    (newPassword?.get('cnPpassword')?.invalid &&
                      (newPassword?.get('cnPpassword')?.dirty ||
                        newPassword?.get('cnPpassword')?.touched)) ||
                    newPassword?.get('password')?.value !==
                      newPassword?.get('cnPpassword')?.value
                  "
                  class="text-danger"
                >
                  <div
                    *ngIf="
                      newPassword?.get('cnPpassword')?.hasError('required')
                    "
                  ></div>
                  Les mots de passe ne correspondent pas.
                </div>

                <div class="form-group py-3">
                  <!-- <div class="checkbox p-0">
                    <input id="checkbox1" type="checkbox">
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div> -->
                  <button
                    class="btn monBlog d-block w-100"
                    [disabled]="!newPassword.valid"
                    (click)="resetPassword()"
                    type="button"
                  >
                    Modifier
                  </button>
                </div>
                <!-- <p class="mt-4 mb-0">
                  Already have an password?<a
                    class="ms-2"
                    [routerLink]="'/authentication/login/simple'"
                    >Connexion</a
                  >
                </p> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
