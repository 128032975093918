import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AgencesService } from "src/app/services/agences.service";
import { LoginService } from "src/app/services/login.service";
import { StorageService } from "src/app/services/sotarage.service";
import { environment } from "src/environments/environment";
const URL_PHOTO = environment.Url_PHOTO;
@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  connexionReussi = false;
  connexionEchoue = false;
  currentUser: any;
  users: any;
  photo: any;


  constructor(public router: Router, private storage: StorageService, private connexion: LoginService,
    private serviceUser: AgencesService
  ) {

  }

  generateImageUrl(photoFileName: string): string {
    const baseUrl = URL_PHOTO;
    return baseUrl + photoFileName;
  }

  handleAuthorImageError(event: any) {
    event.target.src =
      'https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=';
  }

  getRoleLabel(): string {
  
    if (this.users?.roles?.includes('ROLE_ADMIN')) {
      return 'Admin';
    } else if (this.users?.roles?.includes('ROLE_SUPERADMIN')) {
      return 'Super Admin';
    } else {
      return '';
    }
  }

  private determineUserRoles(): string {
    if (this.users && this.users.roles) {
      if (this.users.roles.some(role => role.name === "ROLE_ADMIN")) {
        return "Admin";
      } else if (this.users.roles.some(role => role.name === "ROLE_SUPERADMIN")) {
        return "Super Admin";
      }
    }
    return "User";
  }
  userRoles: string;

  ngOnInit() {
    if (this.storage.connexionReussi()) {
      this.connexionReussi = true;
      // this.roles = this.storage.recupererUser().roles;
    }

    //AFFICHER LA PHOTO DE USER CONNECTER
    this.serviceUser.AfficherPhotoUserConnecter().subscribe((data) => {
      this.photo = data;
    });

    this.currentUser = this.storage.recupererUser();


    this.serviceUser.AfficherUserConnecter().subscribe((data) => {
      this.users = data[0];
      // Logic to determine user roles
      this.userRoles = this.determineUserRoles();
    });

  }


  logoutFunc(): void {
    this.connexion.logout().subscribe({
      next: res => {
        this.storage.clean();
        this.router.navigateByUrl('auth/login');
      },
      error: err => {
      }
    });
  }
}
