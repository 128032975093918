import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { StorageService } from "src/app/services/sotarage.service";
import { LoginService } from "src/app/services/login.service";
// import { faHome, faUser, fabiens, faWidget, faProject, faTable, faLayout, faOthers } from '@fortawesome/fontawesome-free-solid';

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy, OnInit {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;
  currentUser: any;
  Userconnecter: any;
  permissionsMange=false;
  superAdmin=false;

  constructor(private router: Router, private storage: StorageService,
    private connexion: LoginService,) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }
  ngOnInit(): void {
    this.connexion.AfficherUserConnecter().subscribe((data) => {
      this.Userconnecter = data[0];
     

      if(this.Userconnecter.permissions.includes('MANAGE_PERMISSION_VISIBILITY') || this.Userconnecter.permissionsRoles.includes('MANAGE_PERMISSION_VISIBILITY')){
        this.permissionsMange = true;
      }
      
      if( this.Userconnecter.profil=="SUPERADMIN"){
        this.superAdmin = true;
      }

    });
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [

    {
      headTitle1: " ",
      headTitle2: "Ready To Use Apps.",
    },

    {
      title: "Biens immobiliers",
      type: "sub",
      icon: "disc",
      active: false,
      children: [
        { path: "/biens/ajouteBien", title: "Ajouter Bien", type: "link" },
        // {
        //   path: "/biens/product/list",
        //   title: "Ventes",
        //   type: "link",
        // },
        {
          path: "/biens/list",
          title: "Liste",
          type: "link",
        },
      ],
    },
    
    {
      title: "Candidatures",
      icon: "shopping-bag",
      type: "sub",
      active: false,
      children: [
        { path: "/biens/candidature", title: "Liste", type: "link" },
  
      ],
    },

    {
      title: "Gestion des acteurs",
      type: "sub",
      icon: "settings",
      active: false,
      children: [
        {
          path: "/users/users/alluser",
          title: "Tous les acteurs",
          type: "link",
        },
        {
          path: "/user/liste-agence",
          title: "Agences",
          type: "link",
        },
        {
          path: "/users/agents",
          title: "Agents",
          type: "link",
        },
        {
          path: "/user/liste-bailleur",
          title: "Bailleurs",
          type: "link",
        },
        {
          path: "/user/liste-locataire",
          title: "Locataires",
          type: "link",
        },
      ],
    },

    {
      title: "Gestion des incidents",
      icon: "alert-triangle",
      type: "sub",
      active: false,
      children: [
        { path: "biens/probleme", title: "Incidents déclarés", type: "link" },
        { path: "biens/reparations", title: "Réparations", type: "link" },
      
      ],
    },

    {
      title: "Blog",
      icon: "blog",
      active: false,
      type: "link",
      path: "/blog",
    },

    {
      title: "Gestion des abonnements",
      icon: "rss",
      type: "sub",
      active: false,
      children: [
        { path: "/abonnement/liste-abonnement", title: "Packages", type: "link" },
        { path: "/abonnement/liste", title: "Abonnement par utilisateur", type: "link" },
        { path: "/abonnement/abonnement_encours", title: "Encours", type: "link" },
        { path: "/abonnement/abonnement_expire", title: "Expirés", type: "link" },
        { path: "/abonnement/abonnement_expire_5jours", title: "Expirants dans 5 jours", type: "link" },
  
      ],
    },
    // {
    //   title: "Gestion des transactions",
    //   icon: "blog",
    //   active: false,
    //   type: "link",
    //   path: "/gestionTransaction/transaction",
    // },

    {
      title: "Gestion des transactions",
      icon: "dollar-sign",
      type: "sub",
      active: false,
      children: [
        // { path: "/gestionTransaction/factures", title: "Factures", type: "link" },
        { path: "/gestionTransaction/transaction", title: "Liste", type: "link" },
        // { path: "/gestionTransaction/recu", title: "Réçu", type: "link" },  
      ],
    },

    // {
    //   title: "Gestion des factures",
    //   icon: "blog",
    //   active: false,
    //   type: "link",
    //   path: "/gestionTransaction/factures",
    // },

    {
      title: "Gestion des factures",
      icon: "dollar-sign",
      type: "sub",
      active: false,
      children: [
        { path: "/gestionTransaction/factures", title: "Liste", type: "link" },
        // { path: "/gestionTransaction/transaction", title: "Transaction", type: "link" },
        // { path: "/gestionTransaction/recu", title: "Réçu", type: "link" },  
      ],
    },

    // {
    //   path: "/user/liste-agence",
    //   title: "Agence",
    //   icon: "others",
    //   type: "link",
    //   // bookmark: true,
    // },
    // {
    //   title: "Agents",
    //   icon: "user",
    //   active: false,
    //   type: "link",
    //   path: "/users/agents",
    // },
    

    // {
    //   title: "Utilisateurs",
    //   icon: "user",
    //   active: false,
    //   type: "link",
    //   path: "/users/users/alluser",
    // },

  
  
    {
      title: "Gestion des droits",
      icon: "shield",
      type: "sub",
      active: false,
      children: [
        { path: "/droits/rolepermissions/AGENCE", title: "Role", type: "link" },
        { path: "/droits/permissions", title: "Permissions", type: "link" },  
        { path: "/droits/utilisateurPermissions", title: "Utilisateur", type: "link" },
      ],
    },
    {
      title: "Historique des logs",
      icon: "file",
      active: false,
      type: "link",
      path: "/droits/logs",
    },

    {
      title: "Configurations",
      type: "sub",
      icon: "settings",
      active: false,
      children: [
        {
          path: "/configuration/commodite",
          title: "Commodites",
          type: "link",
        },
        {
          path: "/configuration/statut",
          title: "Statut",
          type: "link",
        },
        {
          path: "/configuration/type-bien",
          title: "Type de bien",
          type: "link",
        },
        {
          path: "/configuration/caracteristique",
          title: "Caracteristiques",
          type: "link",
        },
        {
          path: "/configuration/periode",
          title: "Periodes",
          type: "link",
        },
        {
          path: "/configuration/smtp-mail",
          title: "SMTP MAIL",
          type: "link",
        },
        {
          path: "/configuration/mode-paiement",
          title: "Mode de paiement",
          type: "link",
        },
        {
          path: "/configuration/statut-rdv",
          title: "Statut rdv",
          type: "link",
        },
      ],
    },
    {
      title: "Adresses",
      type: "sub",
      icon: "map-pin",
      active: false,
      children: [
        {
          path: "/adresse/pays",
          title: "Pays",
          type: "link",
        },
        {
          path: "/adresse/region",
          title: "Regions",
          type: "link",
        },
        {
          path: "/adresse/cercle",
          title: "Cercle",
          type: "link",
        },
        {
          path: "/adresse/commune",
          title: "Communes",
          type: "link",
        }
      ],
    },


    
    
    
  ];

  isUserAdminOrSuperAdmin(): boolean {
    const userRole = this.currentUser?.user?.role[0];
    // alert ("je suis "+ userRole)
    return userRole === "ROLE_ADMIN";
  }
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
 