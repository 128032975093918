<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/">
              <img
                class="img-fluid for-light"
                src="assets/images/logo/sossara.png"
                alt="looginpage"
                style="   max-width: 40%;"
              />
              <img
                class="img-fluid for-dark"
                src="assets/images/logo/sossara_dark.png"
                alt="looginpage"
                style="    max-width: 40%;"
              />
            </a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <h4>Se connecter à son compte</h4>
              <p>Saisez votre email et mot passe</p>
              <div class="form-group">
                <label class="col-form-label">Adresse email</label>
                <input
                  class="form-control"
                  type="email"
                  required=""
                  placeholder="sossara@admin.com"
                  formControlName="email"
                />
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.required
                  "
                  class="text text-danger mt-1"
                >
                  Email est obligatoire
                </div>
                <div
                  *ngIf="
                    loginForm.controls.email.touched &&
                    loginForm.controls.email.errors?.email
                  "
                  class="text text-danger mt-1"
                >
                  Adresse email invalide
                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Mot de passe</label>
                <input
                  class="form-control"
                  [type]="show ? 'text' : 'password'"
                  formControlName="password"
                  required=""
                  placeholder="*********"
                />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show">
                  <i class="fa fa-eye"></i>
                </div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show">
                  <i class="fa fa-eye-slash"></i>
                </div>
              </div>
              <div
                *ngIf="
                  loginForm.controls.password.touched &&
                  loginForm.controls.password.errors?.required
                "
                class="text text-danger mt-1"
              >
                Mot de passe oblogatoire
              </div>
              <div class="form-group mb-0">
                <!-- <div class="form-group mb-0">
                  <div class="checkbox p-0">
                   
                    <label class="text-muted" for="checkbox1">Remember password</label>
                  </div>
                  <a [routerLink]="'/authentication/forgot-password'" class="link">Mot de pass oublié?</a>
                
                </div> -->
              
                <p class="d-flex flex-column align-items-end">
                  <a [routerLink]="'/forgot-password'">Mot de pass oublié?</a></p>
                <div class="text-end mt-3">
                  <button
                    class="btn monBlog d-block w-100"
                    [disabled]="!loginForm.valid"
                    (click)="login()"
                    type="button"
                  >
                    <span>Se connecter</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
