import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { StorageService } from "./sotarage.service";

const env = environment.Url_BASE;

@Injectable({
  providedIn: "root",
})
export class BienService {
  // storageService: any;
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) { }

  private accessToken: string;

  setAccessToken(token: string) {
    this.accessToken = token;
  }

  getHeaders(): HttpHeaders {
    const token = this.storageService.getUser().token;
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
  }
  //afficher tous les biens
  allBien(): Observable<any> {
    return this.http.get(`${env}/bien/afficherbienTotal`);
  }

  SupprimerBien(uid: any): Observable<any> {
    const headers = this.getHeaders();

    return this.http.put(`${env}/bien/supprimer/${uid}`, null, {
      headers,
    });
  }


  PublierBien(uid: any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.put(`${env}/bien/publier/${uid}`, null, {
      headers,
    });
  }

  //AJOUTER UN BIEN
    registerBien(
    commodite: any[],
    type: number,
    caracteristique: number,
    commune: number,
    nb_piece: any,
    nom: string,
    chambre: any,
    cuisine: any,
    toilette: any,
    surface: any,
    prix: any,
    statut: any, // Assurez-vous que le statut est envoyé en tant que chaîne de caractères
    description: string,
    quartier: string,
    rue: string,
    porte: string,
    periode: number,
    caution: any,
    avance: any,
    longitude: number,
    latitude: number,
    photos: File[]
  ): Observable<any> {
    const headers = this.getHeaders();
    const formData = new FormData();
    if (typeof prix === 'string') {
      prix = prix.replace(/\s/g, '');
    } else if (prix) {
      prix = prix.toString().replace(/\s/g, '');
    } else {
      prix = '0';
    }

    if (commodite && commodite.length > 0) {
      commodite.forEach(i => { formData.append('commodites[]', i.toString()); });
    }
    // commodite.forEach(i => { formData.append('commodites[]', i); });
    formData.append('typeImmoId', type.toString());
    formData.append('commune', commune.toString());
    formData.append('nb_piece', nb_piece || 0);
    formData.append('nom', nom);
    formData.append('chambre', chambre || 0);
    formData.append('cuisine', cuisine || 0);
    formData.append('toilette', toilette || 0);
    formData.append('surface', surface || 0);
    formData.append('prix', prix.toString());
    formData.append('statutId', statut);
    formData.append('description', description);
    formData.append('quartier', quartier);
    formData.append('rue', rue || '');
    formData.append('porte', porte || '');
    // Si le statut est "A vendre", définissez la période sur 6
    if (statut === "2") {
      formData.append('periodeId', '2');
    } else {
      formData.append('periodeId', periode.toString());
    }

    if (statut === "1") {
      formData.append('caracteristiqueId', '1');
    } else {
      formData.append('caracteristiqueId', caracteristique.toString());
    }

    formData.append('caution', caution || 0);
    formData.append('avance', avance || 0);
    formData.append('longitude', longitude.toString());
    formData.append('latitude', latitude.toString());
    photos.forEach(p => { formData.append('photoImmos', p); });


    // Vous pouvez supposer que URL_BASE est défini ailleurs dans votre code
    return this.http.post(
      env + `/bien/ajouter`,
      formData,
      { headers }
    );
  
  }



  //   commodite: any[],
  //   type: number,
  //   caracteristique: number,
  //   commune: number,
  //   nb_piece: any,
  //   nom: string,
  //   chambre: any,
  //   cuisine: any,
  //   toilette: any,
  //   surface: number,
  //   prix: number,
  //   statut: string, // Assurez-vous que le statut est envoyé en tant que chaîne de caractères
  //   description: string,
  //   quartier: string,
  //   rue: string,
  //   porte: string,
  //   periode: number,
  //   caution: any,
  //   avance: any,
  //   longitude: number,
  //   latitude: number,
  //   photos: File[]
  // ): Observable<any> {
  //   const headers = this.getHeaders();
  //   const formData = new FormData();

  //   commodite.forEach(i => { formData.append('commodites[]', i); });
  //   formData.append('typeImmoId', type.toString());
  //   formData.append('caracteristiqueId', caracteristique.toString());
  //   formData.append('commune', commune.toString());
  //   formData.append('nb_piece', nb_piece || 0);
  //   formData.append('nom', nom);
  //   formData.append('chambre', chambre || 0);
  //   formData.append('cuisine', cuisine || 0);
  //   formData.append('toilette', toilette || 0);
  //   formData.append('surface', surface.toString());
  //   formData.append('prix', prix.toString());
  //   formData.append('statutId', statut);
  //   formData.append('description', description);
  //   formData.append('quartier', quartier);
  //   formData.append('rue', rue || '');
  //   formData.append('porte', porte || '');
  //   // Si le statut est "A vendre", définissez la période sur 6
  //   if (statut === "2") {
  //     formData.append('periodeId', '2');
  //   } else {
  //     formData.append('periodeId', periode.toString());
  //   }

  //   formData.append('caution', caution || 0);
  //   formData.append('avance', avance || 0);
  //   formData.append('longitude', longitude.toString());
  //   formData.append('latitude', latitude.toString());
  //   photos.forEach(p => { formData.append('photoImmos', p); });


  //   // Vous pouvez supposer que URL_BASE est défini ailleurs dans votre code
  //   return this.http.post(
  //     env + `/bien/ajouter`,
  //     formData,
  //     { headers }
  //   );
  // }

  //AFFICHER LA LISTE DES CARACTERISTIQUE
  AfficherCaracteristique(): Observable<any> {
    return this.http.get(env + `/caracteristique/afficher`);
  }


  commodite(): Observable<any> {
    return this.http.get(env + `/type/immo`);
  }

  // admin/bien/imo/get/rent
  bienVendu(): Observable<any> {
    const headers = this.getHeaders();

    return this.http.get(`${env}/admin/bien/immo/get/sell`, { headers });
  }
  bienLouer(): Observable<any> {
    const headers = this.getHeaders();

    return this.http.get(`${env}/admin/bien/immo/get/rent`, { headers });
  }

  ModifierBien(
    commodite: any[],
    type: number,
    caracteristique: number,
    commune: number,
    nb_piece: any,
    nom: string,
    chambre: any,
    cuisine: any,
    toilette: any,
    surface: any,
    prix: any,
    statut: any,
    description: string,
    quartier: string,
    rue: string,
    porte: any,
    periode: number,
    caution: any,
    avance: any,
    longitude: number,
    latitude: number,
    photos: File[],
    id: any
  ): Observable<any> {
    const headers = this.getHeaders();
    const formData = new FormData();
    if (commodite && commodite.length > 0) {
      commodite.forEach(i => { formData.append('commodites[]', i.toString()); });
    }
    if (typeof prix === 'string') {
      prix = prix.replace(/\s/g, '');
    } else if (prix) {
      prix = prix.toString().replace(/\s/g, '');
    } else {
      prix = '0';
    }
    formData.append('typeImmoId', type.toString());
    formData.append('caracteristiqueId', caracteristique.toString());
    formData.append('commune', commune.toString());
    formData.append('nom', nom);
    // formData.append('surface', surface.toString());
    formData.append('surface', surface || 0);
    formData.append('prix', prix.toString());
    formData.append('statutId', statut);
    formData.append('description', description);
    formData.append('quartier', quartier);

    // Si le statut est "A vendre", définissez la période sur 6
    if (statut === '2') {
      formData.append('periodeId', '2');
    } else {
      formData.append('periodeId', periode.toString());
    }

    formData.append('caution', caution || 0);
    formData.append('avance', avance || 0);
    formData.append('rue', rue || '');
    formData.append('porte', porte || '');
    formData.append('chambre', chambre || 0);
    formData.append('cuisine', cuisine || 0);
    formData.append('toilette', toilette || 0);
    formData.append('nb_piece', nb_piece || 0);
    // }
    formData.append('longitude', longitude.toString());
    formData.append('latitude', latitude.toString());
    photos.forEach(p => { formData.append('photoImmos', p); });
    formData.append('uid', id.toString()); // Convertir l'identifiant en chaîne de caractères

    return this.http.put(
      env + '/bien/modifierbien/' + `${id}`,
      formData,
      { headers }
    );
  }

  //AFFICHER UN BIEN IMMO EN FONCTION DE SON ID
  AfficherBienImmoParId(id: number): Observable<any> {
    return this.http.get(`${env}/bien/afficherbienparid/${id}`);
  }

  //AFFICHER UN BIEN IMMO EN FONCTION DE SON ID admin/user/all/get
  AfficherBienParUserConnecte(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/bien/immo/user`, { headers });
  }


  getCountries(): Observable<any[]> {
    return this.http.get<any[]>('assets/i18n/CountryCodes.json');
  }

  AjouterPays(formData: FormData): Observable<any> {
    const headers = this.getHeaders();
    return this.http.post(`${env}/pays/ajouter`, formData, { headers });
  }

  AjouterCommodite(formData: FormData): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/commodite/ajouter`, formData, { headers });
  }
  AjouterRegion(id_pays: number, formData: FormData): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/region/ajouter/${id_pays}`, formData, { headers });
  }

  AjouterCercle(id_region: number, formData: FormData): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/cercle/ajouter/${id_region}`, formData, { headers });
  }

  AjouterCommune(id_cercle: number, formData: FormData): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/commune/ajouter/${id_cercle}`, formData, { headers });
  }

  AjouterType(formData: FormData): Observable<any> {
    const headers = this.getHeaders();

    return this.http.post(`${env}/typeimmo/ajouter`, formData, { headers });
  }


  AfficherCandidature(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/candidature/affichercandidature`, { headers });
  }



  AfficherCandidatureParUuid(uuid:any): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/candidature/affichercandidatureparuuidAdmin/${uuid}`, { headers });
  }

  AfficherAllProbleme(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/probleme/afficherprobleme`, { headers });
  }

  //AFFICHER LA LISTE DES STATUTS PROBLEMES
  AfficherListeStatutProbleme(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(`${env}/statutprobleme/afficher`, { headers });
  }

  //AFFICHER UN PROBLEME EN FONCTION DE SON UUID
  AfficherProblemeParUuId(uuid: any): Observable<any> {
    const headers = this.getHeaders();

    return this.http.get(`${env}/probleme/afficherparuuid/${uuid}`, { headers });
  }
  AfficherListePeriode(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/periode/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES PAYS
  AfficherListePays(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/pays/afficher`, { headers });
  }


  //AFFICHER LA LISTE DES REGIONS
  AfficherListeRegion(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/region/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES CERCLE
  AfficherListeCercle(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/cercle/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES COMMUNE
  AfficherListeCommune(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/commune/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES TYPES BIEN IMMO
  AfficherListeTypeBien(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/typeimmo/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES STATUTS BIEN IMMO
  AfficherListeStatutBien(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/statutbien/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES TYPES DE BIENS
  AfficherListeTypeImmo(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/typeimmo/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES STATUTS DE BIENS
  AfficherListeStatut(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/statutbien/afficher`, { headers });
  }

  //AFFICHER LA LISTE DES COMMODITES
  AfficherListeCommodite(): Observable<any> {
    return this.http.get(env + `/commodite/afficher`);
  }

  AfficherListeStatutRdv(): Observable<any> {
    const headers = this.getHeaders();
    return this.http.get(env + `/statutrdv/afficher`, { headers });
  }


}
