<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a routerLink="/">
    <img
      class="img-fluid for-light"
      src="assets/images/logo/sossara.png"
      alt=""
      style="   max-width: 70%;"
    />
    <img
      class="img-fluid for-dark"
      src="assets/images/logo/sossara_dark.png"
      alt=""
      style="   max-width: 70%;"
    />
  </a>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <div class="toggle-sidebar" (click)="sidebarToggle()">
    <i data-feather="grid" class="status_toggle middle sidebar-toggle"></i>
    <!-- <app-feather-icons icon="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons> -->
  </div>
</div>
<div class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" />
  </a>
</div>
<nav class="sidebar-main">
  <div
    class="left-arrow"
    id="left-arrow"
    [class.d-none]="leftArrowNone"
    (click)="scrollToLeft()"
  >
    <!-- <i data-feather="arrow-left" ></i> -->
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>
  <div
    id="sidebar-menu"
    [ngStyle]="{
      marginLeft:
        this.layout.config.settings.layout == 'Rome' ||
        'Singapore' ||
        'Barcelona'
          ? margin + 'px'
          : '0px'
    }"
  >
    <ul class="sidebar-links custom-scrollbar">
      <div class="simplebar-wrapper">
        <div class="simplebar-mask">
          <div class="simplebar-offset">
            <div class="simplebar-content-wrapper">
              <div class="simplebar-content">
                <li class="back-btn">
                  <a href="javascript:void(0)">
                    <img
                      class="img-fluid"
                      src="assets/images/logo/logo-icon.png"
                      alt=""
                    />
                  </a>
                  <div class="mobile-back text-end" (click)="sidebarToggle()">
                    <span>Back</span>
                    <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                  </div>
                </li>
                <li
                  class="monLi"
                  *ngIf="profil == 'ADMIN'"
                  [ngClass]="{ active: isMenuItemActive('/dashboard/admin') }"
                >
                  <a [routerLink]="['/dashboard/admin']"
                    ><app-feather-icons [icon]="'home'"></app-feather-icons>
                    <span>Dashboard</span></a
                  >
                </li>
                <li
                  class="monLi"
                  *ngIf="profil === 'SUPERADMIN'"
                  [ngClass]="{
                    active: isMenuItemActive('/dashboard')
                  }"
                >
                  <a [routerLink]="['/dashboard']">
                    <app-feather-icons [icon]="'home'"></app-feather-icons>
                    <span> Dashboard</span></a
                  >
                </li>

                <li
                  [class]="
                    menuItem.headTitle1 ? 'sidebar-main-title' : 'sidebar-list'
                  "
                  *ngFor="let menuItem of menuItems"
                  [ngClass]="{ active: menuItem.active }"
                >
                  <div *ngIf="menuItem.headTitle1">
                    <h6 class="lan-1">{{ menuItem.headTitle1 | translate }}</h6>
                  </div>

                  <label
                    class="badge badge-{{ menuItem.badgeType }}"
                    *ngIf="menuItem.badgeType && menuItem.badgeValue"
                    >{{ menuItem.badgeValue }}</label
                  >
                  <a
                    href="javascript:void(0)"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="menuItem.type === 'sub' && menuItem.icon === 'disc'"
                    (click)="toggletNavActive(menuItem)"
                  >
                    <app-feather-icons [icon]="'disc'"></app-feather-icons>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="
                      menuItem.type === 'sub' &&
                      menuItem.icon === 'alert-triangle'
                    "
                    (click)="toggletNavActive(menuItem)"
                  >
                    <app-feather-icons
                      [icon]="'alert-triangle'"
                    ></app-feather-icons>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="
                      menuItem.type === 'sub' && menuItem.icon === 'shield'
                    "
                    (click)="toggletNavActive(menuItem)"
                  >
                    <app-feather-icons [icon]="'shield'"></app-feather-icons>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="
                      menuItem.type === 'sub' && menuItem.icon === 'dollar-sign' 
                    "
                    (click)="toggletNavActive(menuItem)"
                  >
                    <app-feather-icons
                      [icon]="'dollar-sign'"
                    ></app-feather-icons>

                    <span>{{ menuItem.title | translate }}</span>

                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>

                  <a
                    href="javascript:void(0)"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="
                      menuItem.type === 'sub' &&
                      menuItem.icon === 'shopping-bag'
                    "
                    (click)="toggletNavActive(menuItem)"
                  >
                    <app-feather-icons
                      [icon]="'shopping-bag'"
                    ></app-feather-icons>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="
                      menuItem.type === 'sub' && menuItem.icon === 'settings'
                    "
                    (click)="toggletNavActive(menuItem)"
                  >
                    <app-feather-icons [icon]="'settings'"></app-feather-icons>

                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>
                  <a href="javascript:void(0)" class="sidebar-link sidebar-title" 
                  [class.link-nav]="!menuItem.children"
                  [ngClass]="{ active: menuItem.active }" 
                  *ngIf="menuItem.type === 'sub' && menuItem.icon === 'rss'" 
                  (click)="toggletNavActive(menuItem)">
                  
                  <app-feather-icons [icon]="'rss'" ></app-feather-icons>
               
                  <span>{{ menuItem.title | translate }}</span>
                  
                  <div class="according-menu">
                     <i class="fa fa-angle-{{
                         menuItem.active ? 'down' : 'right'
                       }} pull-right" *ngIf="menuItem.children"></i>
                  </div>
               </a>
               <a href="javascript:void(0)" class="sidebar-link sidebar-title" 
                  [class.link-nav]="!menuItem.children"
                  [ngClass]="{ active: menuItem.active }" 
                  *ngIf="menuItem.type === 'sub' && menuItem.icon === 'map-pin'" 
                  (click)="toggletNavActive(menuItem)">
                  
                  <app-feather-icons [icon]="'map-pin'" ></app-feather-icons>
               
                  <span>{{ menuItem.title | translate }}</span>
                  
                  <div class="according-menu">
                     <i class="fa fa-angle-{{
                         menuItem.active ? 'down' : 'right'
                       }} pull-right" *ngIf="menuItem.children"></i>
                  </div>
               </a>

                  <a
                    [routerLink]="!menuItem.type ? null : [menuItem.path]"
                    routerLinkActive="active"
                    class="sidebar-link sidebar-title"
                    [class.link-nav]="!menuItem.children"
                    [ngClass]="{ active: menuItem.active }"
                    *ngIf="menuItem.type === 'link'"
                  >
                    <app-svg-icon [icon]="menuItem.icon"></app-svg-icon>
                    <span>{{ menuItem.title | translate }}</span>
                    <div class="according-menu">
                      <i
                        class="fa fa-angle-{{
                          menuItem.active ? 'down' : 'right'
                        }} pull-right"
                        *ngIf="menuItem.children"
                      ></i>
                    </div>
                  </a>
                  <ul
                    class="sidebar-submenu"
                    [ngClass]="{ active: menuItem.active }"
                    [ngClass]="{
                      'menu-open': menuItem.active,
                      'menu-close': !menuItem.active
                    }"
                    *ngIf="menuItem.children"
                    [style.display]="menuItem.active ? 'block' : 'none'"
                  >
                    <li
                      *ngFor="let childrenItem of menuItem.children"
                      [ngClass]="{ active: childrenItem.active }"
                    >
                      <a
                        class="submenu-title"
                        [routerLink]="
                          !childrenItem.type ? null : [childrenItem.path]
                        "
                        *ngIf="childrenItem.type === 'link'"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                      >
                        {{ childrenItem.title | translate }}
                        <i
                          class="fa fa-angle-down pull-right"
                          *ngIf="childrenItem.children"
                        ></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
  <div
    class="right-arrow"
    id="right-arrow"
    [class.d-none]="rightArrowNone"
    (click)="scrollToRight()"
  >
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>
