<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
  <span class="badge rounded-pill badge-secondary">{{notif}}</span>
</div>
<div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">Les Notifications</h6> 
  <ul>
    <ng-container *ngFor="let Notification of notifications; let i = index"> 
      <li class="b-l-primary border-4" *ngIf="i < 5">
        <p>{{Notification?.sender?.nom}} <span class="font-danger">{{ getDurationLabel(Notification?.date) }}</span></p>
        <p [routerLink]="'/biens/candidature'" style="cursor: pointer;">
          <b>Type:</b> {{ removeUnderscoresAndDashes(Notification?.type) }}
        </p>
              </li>
    </ng-container>
    <li><a class="f-w-700" [routerLink]="'/notifications/Paiements'">Voir plus</a></li>
  </ul>
  
</div>

<!-- <div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">Les Notifications</h6> 
  <ul>
    <ng-container *ngFor="let candidature of total; let i = index"> 
      <li class="b-l-primary border-4" *ngIf="i < 4">
        <p>{{candidature.locataire.nom}} <span class="font-danger">Il y a {{ getDurationLabel(candidature?.createdAt) }}</span></p>
        <p [routerLink]="'/biens/candidature'" style="cursor: pointer;"><b>Bien:</b> {{candidature?.bien?.nom.slice(0, 25)}}... </p>
      </li>
    </ng-container>
    <li><a class="f-w-700" [routerLink]="'/biens/candidature'">Voir plus</a></li>
  </ul>
  
</div> -->

