<div class="media profile-media">
  <img
    class="b-r-10"
    src="{{ generateImageUrl(photo?.photo?.nom) }}"
    (error)="handleAuthorImageError($event)"
    alt=""
  />
  <div class="media-body">
    <span>{{ users?.nom }}</span>
    <p class="mb-0 font-roboto">
      {{
        users?.profil
      }}
      <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <a href="/user/modifier-profile"
      ><app-feather-icons [icon]="'user'"></app-feather-icons
      ><span>Compte</span></a
    >
  </li>
  <!-- <li><a href="email"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
  <!-- <li><a href="/to-do"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <!-- <li>
    <a href="/user/modifier-profile"
      ><app-feather-icons [icon]="'settings'"></app-feather-icons
      ><span>Paramètres</span></a
    >
  </li> -->
  <li (click)="logoutFunc()">
    <app-feather-icons [icon]="'log-in'"></app-feather-icons
    ><span>Déconnexion</span>
  </li>
</ul>
